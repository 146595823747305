import React, { useEffect } from "react";
import {
  Stack,
  TextField,
  PrimaryButton,
  IIconProps,
  useTheme,
} from "@fluentui/react";

interface CustomSearchBoxProps {
  input?: string;
  disabled?: boolean;
  handleInputChange: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => void;
  onSearchClick: () => void;
  placeholder: string;
}

const CustomSearchBox: React.FC<CustomSearchBoxProps> = ({
  input,
  disabled,
  handleInputChange,
  onSearchClick,
  placeholder,
}) => {
  const theme = useTheme();
  const searchIcon: IIconProps = { iconName: "Search" };

  useEffect(() => {
    if (!input) {
      onSearchClick();
    }
  }, [input]);

  return (
    <Stack horizontal>
      <Stack.Item align="start">
        <PrimaryButton
          disabled={disabled}
          styles={{
            root: { minWidth: "5px", borderRadius: "4px 0 0 4px" },
          }}
          iconProps={searchIcon}
          onClick={onSearchClick}
        ></PrimaryButton>
      </Stack.Item>
      <Stack.Item grow align="end">
        <TextField
          styles={{
            fieldGroup: {
              borderRadius: "0 4px 4px 0",
              borderColor: theme.palette.neutralLight,
            },
          }}
          disabled={disabled}
          placeholder={placeholder}
          value={input}
          onChange={handleInputChange}
        />
      </Stack.Item>
    </Stack>
  );
};

export default CustomSearchBox;
