import StandardForm from "../../common/components/StandardForm";
import Blobs from "../Blobs/Blobs";
import { IStackStyles, Stack } from "@fluentui/react";
import { useAppDispatch } from "../../common/state";
import { setAccountName } from "../../common/state/main";
import Containers from "../Containers/Containers";
import { useState } from "react";
import EngagementView from "./EngagementView";
import CustomSearchBox from "../../common/components/CustomSearchBox";
import { resourceApi } from "../../common/api/api";

const stackStyles: IStackStyles = {
  root: {
    flex: 1,
    overflow: "hidden",
    width: `100%`,
  },
};
const Home = () => {
  const [inputAccountName, setInputAccountName] = useState("");
  const dispatch = useAppDispatch();

  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    setInputAccountName(value || "");
    if (value === "" || value === undefined) {
      console.log("Invalidating cache for containers");

      dispatch(resourceApi.util.invalidateTags(["containers"])); // Invalidate the cache for the "AccountName" tag
    }
  };
  return (
    <div>
      <StandardForm
        widthPercent={90}
        mediumWidthPercent={90}
        smallWidthPercent={90}
      >
        <EngagementView />
        <Stack horizontal tokens={{ childrenGap: 10 }} styles={stackStyles}>
          <Stack.Item grow align="start">
            <Stack
              style={{
                marginTop: "99px",
              }}
            >
              <CustomSearchBox
                input={inputAccountName}
                handleInputChange={handleInputChange}
                onSearchClick={() => dispatch(setAccountName(inputAccountName))}
                placeholder="Enter storage account name"
              />
              <Containers />
            </Stack>
          </Stack.Item>

          <Blobs />
        </Stack>
      </StandardForm>
    </div>
  );
};

export default Home;
