import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useEffect } from "react";

const useError = (error: any, setErrorMessage: (message: string) => void) => {
  const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
    return error && typeof error === "object" && "data" in error;
  };

  const isSerializedError = (error: any): error is SerializedError => {
    return error && typeof error === "object" && "message" in error;
  };
  useEffect(() => {
    if (error) {
      let errorMessage = "An unknown error occurred";
      if (
        isFetchBaseQueryError(error) &&
        error.data &&
        typeof error.data === "object" &&
        "message" in error.data
      ) {
        errorMessage = (error.data as { message: string }).message;
      } else if (isSerializedError(error)) {
        errorMessage = error.message || "An unknown error occurred";
      }
      setErrorMessage(errorMessage);
    }
  }, [error]);
};

export default useError;
