import React from "react";
import { DatePicker, DayOfWeek, Stack } from "@fluentui/react";

interface DateRangePickerProps {
  startDate: Date | undefined;
  endDate: Date | undefined;
  onStartDateChange: (date: Date | null | undefined) => void;
  onEndDateChange: (date: Date | null | undefined) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}) => {
  return (
    <Stack horizontal tokens={{ childrenGap: 10 }}>
      <DatePicker
        label="Start date"
        styles={{ root: { width: 150 } }}
        value={startDate}
        onSelectDate={onStartDateChange}
        isRequired={false}
        firstDayOfWeek={DayOfWeek.Sunday}
        placeholder="Select a start date..."
        ariaLabel="Select a start date"
      />
      <DatePicker
        label="End date"
        value={endDate}
        styles={{ root: { width: 150 } }}
        onSelectDate={onEndDateChange}
        isRequired={false}
        firstDayOfWeek={DayOfWeek.Sunday}
        placeholder="Select an end date..."
        ariaLabel="Select an end date"
      />
    </Stack>
  );
};

export default DateRangePicker;
