// rtk slice

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "./store";
import { BlobDetail } from "../../features/Blobs/type";

export type MainState = {
  subscriptionId?: string;
  storageAccountName?: string;
  containerName?: string;
  containerContiuationToken?: string;
  blobContiuationToken?: string;
  blobPrefix?: string;
  searchBlobPrefix?: string;
  appTheme: "light" | "dark";
};

const initialState: MainState = {
  appTheme: "light",
};

const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setSubscriptionId(state, action: PayloadAction<string>) {
      state.subscriptionId = action.payload;
    },
    setAccountName(state, action: PayloadAction<string>) {
      state.storageAccountName = action.payload;
      state.containerName = undefined;
      state.containerContiuationToken = undefined;
      state.blobContiuationToken = undefined;
      state.blobPrefix = undefined;
      state.searchBlobPrefix = undefined;
    },
    setContainerName(state, action: PayloadAction<string>) {
      state.blobPrefix = undefined;
      state.searchBlobPrefix = undefined;
      state.containerName = action.payload;
    },
    setContainerContiuationToken(state, action: PayloadAction<string>) {
      state.containerContiuationToken = action.payload;
    },
    setBlobContiuationToken(state, action: PayloadAction<string>) {
      state.blobContiuationToken = action.payload;
    },
    setBlobPrefix(state, action: PayloadAction<string | undefined>) {
      state.blobPrefix = action.payload;
    },
    setTheme(state, action: PayloadAction<"light" | "dark">) {
      state.appTheme = action.payload;
    },
    setSearchPrefix(state, action: PayloadAction<string | undefined>) {
      state.searchBlobPrefix = action.payload;
    },
  },
});

export const {
  setSubscriptionId,
  setAccountName,
  setContainerName,
  setBlobContiuationToken,
  setContainerContiuationToken,
  setBlobPrefix,
  setTheme,
  setSearchPrefix,
} = mainSlice.actions;

export const selectSubscriptionId = (state: RootState) =>
  state.main.subscriptionId;

export default mainSlice.reducer;
