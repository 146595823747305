import React, { useState, useEffect } from "react";
import { Stack, Text, FontIcon, TeachingBubble } from "@fluentui/react";
import { JobScanResult } from "./types";
import { useId } from "@fluentui/react-hooks";

interface ScanResultsIdProps {
  item: JobScanResult;
}

const ScanResultsId: React.FC<ScanResultsIdProps> = ({ item }) => {
  const [isTeachingBubbleVisible, setIsTeachingBubbleVisible] = useState(false);
  const copyIconId = useId("copyIcon");
  let timeoutId: NodeJS.Timeout;

  const onCopyClick = (scanId: string) => {
    navigator.clipboard.writeText(scanId);
    setIsTeachingBubbleVisible(true);
    timeoutId = setTimeout(() => setIsTeachingBubbleVisible(false), 2000); // Hide after 2 seconds
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Stack horizontal>
      <Text>{item.scanId}</Text>
      <FontIcon
        iconName="Copy"
        id={copyIconId}
        style={{ marginLeft: 8, cursor: "pointer" }}
        onClick={() => onCopyClick(item.scanId)}
      />
      {isTeachingBubbleVisible && (
        <TeachingBubble
          target={`#${copyIconId}`}
          onDismiss={() => setIsTeachingBubbleVisible(false)}
          hasSmallHeadline={true}
          headline="Copied"
          closeButtonAriaLabel="Close"
          calloutProps={{
            calloutWidth: 100,
          }}
        />
      )}
    </Stack>
  );
};

export default ScanResultsId;
