import React from "react";
import { Stack, TooltipHost, Icon } from "@fluentui/react";
import CustomSearchBox from "../../common/components/CustomSearchBox";

interface BlobSearchBoxProps {
  search?: string;
  disabled?: boolean;
  handleInputChange: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => void;
  onSearchClick: () => void;
  placeholder: string;
}

const BlobSearchBox: React.FC<BlobSearchBoxProps> = ({
  search,
  handleInputChange,
  onSearchClick,
  placeholder,
  disabled,
}) => {
  return (
    <Stack.Item align="start">
      <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="center">
        <div style={{ width: 300 }}>
          <CustomSearchBox
            disabled={disabled}
            input={search}
            handleInputChange={handleInputChange}
            onSearchClick={onSearchClick}
            placeholder={placeholder}
          />
        </div>
        <TooltipHost content="Search is case-sensitive. Enter the beginning of the file/folder name to search.">
          <Icon
            iconName="Info"
            styles={{ root: { fontSize: 16, cursor: "pointer" } }}
          />
        </TooltipHost>
      </Stack>
    </Stack.Item>
  );
};

export default BlobSearchBox;
