import React from "react";
import { useGetScanResultDetailsQuery } from "../../common/api/api";
import {
  DetailsListLayoutMode,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from "@fluentui/react";
import { columns } from "./types";
import { useParams } from "react-router-dom";
import useError from "../../common/hooks/useError";

const ScanResultsDetails = () => {
  const { id } = useParams();
  const { data, error } = useGetScanResultDetailsQuery(id!, {
    skip: !id,
    pollingInterval: 60000,
  });

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  useError(error, setErrorMessage);
  const exportListToCSV = () => {
    const replacer = (_key: string, value: any) =>
      value === null ? "" : value;
    if (data === undefined) return;

    const headers = ["timestamp", "sourceRowData", "blobUri"];

    let csv = data.map((row) =>
      headers
        .map((header) => {
          let value;
          if (header === "blobUri") {
            value = row.assetAsJson?.blobStorageUri;
          } else {
            value = row[header];
          }
          return JSON.stringify(value, replacer);
        })
        .join(",")
    );
    csv.unshift(headers.join(","));
    let csvArray = csv.join("\r\n");
    const blob = new Blob([csvArray], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "scan_results.csv";
    document.body.appendChild(a);
    a.click();
    // Clean up
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <Stack>
      <Stack.Item style={{ marginTop: 10, marginLeft: 10 }} align="start">
        <PrimaryButton disabled={!data} onClick={exportListToCSV}>
          Export to CSV
        </PrimaryButton>
      </Stack.Item>
      {errorMessage && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          onDismiss={() => setErrorMessage(undefined)}
          dismissButtonAriaLabel="Close"
        >
          {errorMessage}
        </MessageBar>
      )}
      <ShimmeredDetailsList
        items={data || []}
        enableShimmer={data === undefined && !error}
        columns={columns}
        setKey="set"
        layoutMode={DetailsListLayoutMode.fixedColumns}
        selectionMode={SelectionMode.none}
      />
    </Stack>
  );
};

export default ScanResultsDetails;
