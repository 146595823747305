import React from "react";
import { Modal, Stack, IconButton, IIconProps } from "@fluentui/react";
import DateRangePicker from "../../common/components/DateRangePicker";

interface DateRangeModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  onStartDateChange: (date: Date | null | undefined) => void;
  onEndDateChange: (date: Date | null | undefined) => void;
}

const DateRangeModal: React.FC<DateRangeModalProps> = ({
  isOpen,
  onDismiss,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}) => {
  const cancelIcon: IIconProps = { iconName: "Cancel" };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      isBlocking={false}
      styles={{ main: { maxWidth: 600, padding: 20 } }}
    >
      <Stack>
        <Stack.Item align="end">
          <IconButton
            styles={{ root: { marginLeft: "5" } }}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
        </Stack.Item>
        <Stack.Item>
          <DateRangePicker
            startDate={startDate || undefined}
            endDate={endDate || undefined}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
          />
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

export default DateRangeModal;
