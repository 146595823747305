import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authenticationParameters, authProvider } from "../auth/MsalConfig";
import { AzSubscription } from "../../features/AzSubscriptions/type";
import { StorageAccount } from "../../features/StorageAccounts/type";
import { ContainerResults } from "../../features/Containers/type";
import {
  JobScanResult,
  ContainmentResult,
} from "../../features/ScanResults/types";
import { BlobDetail, BlobResults } from "../../features/Blobs/type";

type ContainerParams = { accountName: string; continuationToken?: string };
type BlobParams = {
  accountName: string;
  containerName: string;
  continuationToken?: string;
  prefix?: string;
  searchPrefix?: string;
};
function getPrefixQueryParam(prefix?: string, searchPrefix?: string): string {
  if (prefix && searchPrefix) {
    return `&prefix=${prefix}${searchPrefix}`;
  } else if (prefix) {
    return `&prefix=${prefix}`;
  } else if (searchPrefix) {
    return `&prefix=${searchPrefix}`;
  }
  return "";
}

export type ScanSubmission = {
  engagement?: string;
  icmNumber?: string;
  blobDetails: BlobDetail[];
};
export const resourceApi = createApi({
  reducerPath: "resourceApi",
  tagTypes: ["containers", "blobs"],
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/storage",
    prepareHeaders: async (headers, {}) => {
      await authProvider
        .acquireTokenSilent(authenticationParameters)
        .then((token) => {
          if (token) {
            if (token.accessToken) {
              headers.set("authorization", `Bearer ${token.accessToken}`);
            }
          }
        });
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAzureSubscriptions: builder.query<AzSubscription[], void>({
      query: () => `subscriptions`,
      keepUnusedDataFor: 60,
    }),
    getStorageAccounts: builder.query<StorageAccount[], string>({
      query: (subscriptionId: string) =>
        `storageAccounts?subscriptionId=${subscriptionId}`,
      keepUnusedDataFor: 60,
    }),
    getContainers: builder.query<ContainerResults, ContainerParams>({
      query: ({ accountName, continuationToken }) =>
        `containers?accountName=${accountName}${
          continuationToken ? `&continuationToken=${continuationToken}` : ""
        }`,
      keepUnusedDataFor: 60,
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.containers.map((x) => ({
                type: "containers" as const,
                x,
              })),
              { type: "containers", id: "PARTIAL-LIST" },
            ]
          : [{ type: "containers", id: "PARTIAL-LIST" }],
    }),
    getBlobs: builder.query<BlobResults, BlobParams>({
      query: ({
        accountName,
        containerName,
        continuationToken,
        prefix,
        searchPrefix,
      }) =>
        `blobs?accountName=${accountName}&containerName=${containerName}${
          continuationToken ? `&continuationToken=${continuationToken}` : ""
        }${getPrefixQueryParam(prefix, searchPrefix)}`,
      keepUnusedDataFor: 60,
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.blobs.map((x) => ({
                type: "blobs" as const,
                x,
              })),
              { type: "blobs", id: "PARTIAL-LIST" },
            ]
          : [{ type: "blobs", id: "PARTIAL-LIST" }],
    }),
    sendBlobDetails: builder.mutation<void, ScanSubmission>({
      query: ({ blobDetails, engagement, icmNumber }) => ({
        url: `scan/?engagement=${engagement}${
          icmNumber ? `&icmNumber=${icmNumber}` : ""
        } `,
        body: blobDetails,
        method: "POST",
      }),
    }),
    getScanResults: builder.query<JobScanResult[], void>({
      query: () => `scanResults`,
    }),
    getScanResultDetails: builder.query<ContainmentResult[], string>({
      query: (id: string) => `scanResults/${id}`,
    }),
  }),
});

export const {
  useGetAzureSubscriptionsQuery,
  useGetStorageAccountsQuery,
  useGetContainersQuery,
  useGetBlobsQuery,
  useSendBlobDetailsMutation,
  usePrefetch,
  useGetScanResultsQuery,
  useGetScanResultDetailsQuery,
  util,
} = resourceApi;
