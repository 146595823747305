import {
  FontIcon,
  HoverCard,
  IColumn,
  IExpandingCardProps,
  mergeStyleSets,
  ProgressIndicator,
  Stack,
  Text,
} from "@fluentui/react";
import JsonDisplay from "../../common/components/JsonDisplay";
import { NavigateFunction } from "react-router-dom";
import ScanResultsId from "./ScanResultsId";

export type ContainmentResult = {
  engagement: string;
  assetType: string;
  assetIdentifier: string;
  assetAsJson: Record<string, string>;
  submitter: string;
  investigationNotes: string;
  spectreLink: string;
  icmNumber: string;
  discoveryReference: string;
  requestedContainmentAction: string;
  priority: string;
  metadata: Record<string, string>;
  tags: Record<string, string>;
};

export type JobScanResult = {
  status: string;
  scanId: string;
  submissionTime: string;
  fileName: string;
  errorMessage: string;
  numberOfSecrets: number;
  percentComplete: number;
  isEnumeratingDirectory?: boolean;
};

export type BlobChunk = {
  blobStorageUri: string;
  stringChunkStartIndex: number;
  stringChunkLength: number;
  metadata: Record<string, string>;
  trackingId: string;
};

const classNames = mergeStyleSets({
  compactCard: {
    wordBreak: "break-word",
    padding: "16px 24px",
  },
  expandedCard: {
    padding: "16px 24px",
  },
});

const onRenderCompactCard = (item: JobScanResult): JSX.Element => {
  return (
    <Stack className={classNames.compactCard}>
      An error occurred processing {item.fileName}
    </Stack>
  );
};

const onRenderExpandedCard = (item: JobScanResult): JSX.Element => {
  return <div className={classNames.expandedCard}>{item.errorMessage}</div>;
};

export function jobColumns(navigate: NavigateFunction) {
  return [
    {
      key: "scanId",
      name: "Scan Target",
      fieldName: "scanId",
      minWidth: 300,
      maxWidth: 500,
      isResizable: true,
      onRender: (item: JobScanResult) => {
        const hasSecrets = item.numberOfSecrets > 0;
        return (
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <FontIcon
              style={{ cursor: hasSecrets ? "pointer" : "default" }}
              onClick={() =>
                hasSecrets ? navigate(`/scanresults/${item.scanId}`) : undefined
              }
              iconName={hasSecrets ? "View" : "Info"}
            ></FontIcon>

            <Stack.Item>{item.fileName}</Stack.Item>
          </Stack>
        );
      },
    },
    {
      key: "submissionTime",
      name: "Submission Time",
      fieldName: "submissionTime",
      minWidth: 100,
      maxWidth: 130,
      isResizable: true,
      onRender: (item: JobScanResult) => (
        <span>
          {new Date(item.submissionTime).toLocaleString("en-US", {
            timeZoneName: "short",
          })}
        </span>
      ),
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      onRender: (item: JobScanResult) => (
        <span>
          {(item.status === "Completed" || item.status === "Failed") && (
            <Text>{item.status}</Text>
          )}

          {item.status !== "Completed" &&
            item.status !== "Failed" &&
            (item.isEnumeratingDirectory ? (
              <>
                <Stack>
                  <Text variant="smallPlus">Enumerating</Text>
                  <Text variant="smallPlus">Directory</Text>
                </Stack>
                <ProgressIndicator styles={{ root: { fontSize: 12 } }} />
              </>
            ) : (
              <ProgressIndicator percentComplete={item.percentComplete / 100} />
            ))}
        </span>
      ),
    },
    {
      key: "completed",
      name: "Completed",
      fieldName: "completed",
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
    },
    {
      key: "numberOfSecrets",
      name: "Secrets Found",
      fieldName: "numberOfSecrets",
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
    },
    {
      key: "errorMessage",
      name: "Error",
      fieldName: "errorMessage",
      minWidth: 50,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: JobScanResult) => {
        const expandingCardProps: IExpandingCardProps = {
          onRenderCompactCard: onRenderCompactCard,
          onRenderExpandedCard: onRenderExpandedCard,
          renderData: item,
        };
        if (item.errorMessage) {
          return (
            <HoverCard
              expandingCardProps={expandingCardProps}
              instantOpenOnClick={true}
            >
              <FontIcon iconName="Error" style={{ color: "red" }}></FontIcon>
            </HoverCard>
          );
        } else {
          <></>;
        }
      },
    },
    {
      key: "scanIdCopy",
      name: "Scan Id",
      fieldName: "scanId",
      minWidth: 300,
      maxWidth: 500,
      isResizable: true,
      onRender: (item: JobScanResult) => <ScanResultsId item={item} />,
    },
  ];
}
export const columns: IColumn[] = [
  {
    key: "assetType",
    name: "Asset Type",
    fieldName: "assetType",
    minWidth: 150,
    maxWidth: 250,
    isResizable: true,
  },

  {
    key: "assetIdentifier",
    name: "Asset Identifier",
    fieldName: "assetIdentifier",
    minWidth: 150,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "engagement",
    name: "Engagement",
    fieldName: "engagement",
    minWidth: 150,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "blobUri",
    name: "Blob URI",
    fieldName: "blobChunk.blobStorageUri",
    minWidth: 200,
    maxWidth: 400,
    isResizable: true,
    onRender: (item: ContainmentResult) => (
      <span>{item.assetAsJson.blobStorageUri}</span>
    ),
  },
  {
    key: "credScanResultAsJson",
    name: "Cred Scan Result As JSON",
    fieldName: "credScanResultAsJson",
    minWidth: 200,
    maxWidth: 400,
    isResizable: true,
    onRender: (item: ContainmentResult) => (
      <JsonDisplay jsonString={JSON.stringify(item.assetAsJson)} />
    ),
  },
  // Add other columns if needed
];
