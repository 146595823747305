import React, { useEffect, useState } from "react";
import { useGetScanResultsQuery } from "../../common/api/api";
import {
  DetailsListLayoutMode,
  Dropdown,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Toggle,
} from "@fluentui/react";
import { jobColumns, JobScanResult } from "./types";
import { useNavigate } from "react-router-dom";
import useError from "../../common/hooks/useError";
import DateRangeModal from "./DateRangeModal";
import { get } from "http";

const options: IDropdownOption[] = [
  { key: "30MIN", text: "Last 30 min" },
  { key: "1HR", text: "Last 1 hour" },
  { key: "4HR", text: "Last 4 hours" },
  { key: "12HR", text: "Last 12 hours" },
  { key: "24HR", text: "Last 24 hours" },
  { key: "48HR", text: "Last 48 hours" },
  { key: "7DAYS", text: "Last 7 days" },
  { key: "CUSTOM", text: "Custom" },
];
const ScanResults = () => {
  const { data, error } = useGetScanResultsQuery(undefined, {
    pollingInterval: 60000,
  });
  const [filteredResults, setFilteredResults] = useState<JobScanResult[]>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showAll, setShowAll] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    setStartDate(getDateFromKey("24HR"));
  }, []);

  useEffect(() => {
    if (data) {
      let results = data;
      if (!showAll) {
        results = results.filter((r) => r.numberOfSecrets > 0);
      }
      if (startDate || endDate) {
        results = results.filter((r) => {
          const submissionDate = new Date(r.submissionTime);
          if (startDate && endDate) {
            return submissionDate >= startDate && submissionDate <= endDate;
          } else if (startDate) {
            return submissionDate >= startDate;
          } else if (endDate) {
            return submissionDate <= endDate;
          }
          return true;
        });
      }
      if (searchQuery) {
        results = results.filter((r) =>
          r.fileName.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      setFilteredResults(results);
    }
  }, [data, showAll, startDate, endDate, searchQuery]);

  const [errorMessage, setErrorMessage] = React.useState<string>();
  useError(error, setErrorMessage);
  const navigate = useNavigate();
  return (
    <div>
      {errorMessage && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          onDismiss={() => setErrorMessage(undefined)}
          dismissButtonAriaLabel="Close"
        >
          {errorMessage}
        </MessageBar>
      )}

      <Stack
        styles={{ root: { padding: "10px" } }}
        horizontal
        horizontalAlign="space-between"
        tokens={{ childrenGap: 10 }}
      >
        <Dropdown
          placeholder="Select a time range"
          options={options}
          styles={{ root: { width: 200 } }}
          dropdownWidth={200}
          defaultSelectedKey={options.find((o) => o.key === "24HR")?.key}
          onChange={onTimeRangeSelect}
        />
        <Toggle
          inlineLabel
          label={showAll ? "Show all" : "Show only with secrets"}
          checked={!showAll}
          onChange={() => setShowAll(!showAll)}
        />
      </Stack>
      <DateRangeModal
        isOpen={isModalOpen}
        onDismiss={closeModal}
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
      />
      <Stack tokens={{ childrenGap: 10 }}>
        <SearchBox
          placeholder="Search by file name"
          onChange={(_, newValue) => setSearchQuery(newValue || "")}
          styles={{ root: { width: 200, marginLeft: "10px" } }}
        />
        <ShimmeredDetailsList
          items={filteredResults || []}
          enableShimmer={data === undefined && error === undefined}
          columns={jobColumns(navigate)}
          setKey="set"
          layoutMode={DetailsListLayoutMode.fixedColumns}
          selectionMode={SelectionMode.none}
        />
      </Stack>
    </div>
  );

  function getDateFromKey(key: string) {
    if (key === "30MIN") {
      return new Date(Date.now() - 30 * 60 * 1000);
    }
    if (key === "1HR") {
      return new Date(Date.now() - 60 * 60 * 1000);
    }
    if (key === "4HR") {
      return new Date(Date.now() - 4 * 60 * 60 * 1000);
    }
    if (key === "12HR") {
      return new Date(Date.now() - 12 * 60 * 60 * 1000);
    }
    if (key === "24HR") {
      return new Date(Date.now() - 24 * 60 * 60 * 1000);
    }
    if (key === "48HR") {
      return new Date(Date.now() - 48 * 60 * 60 * 1000);
    }
    if (key === "7DAYS") {
      return new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    }
  }
  function onTimeRangeSelect(
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ) {
    {
      if (!option || typeof option.key !== "string") return;

      if (option.key === "CUSTOM") {
        openModal();
      } else setStartDate(getDateFromKey(option.key));
    }
  }
};

export default ScanResults;
